var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('S2SForm',{attrs:{"title":"Search Devices"}},[(!_vm.showResults)?_c('S2SCard',{attrs:{"wrap":"","align-center":"","fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"autofocus":"","label":"Terminal ID","placeholder":"Search by Terminal ID","required":"","clearable":"","type":"text","append-icon":"search","loading":_vm.status.loading},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)},"click:append":_vm.search},model:{value:(_vm.deviceId),callback:function ($$v) {_vm.deviceId=$$v},expression:"deviceId"}})],1)],1),(_vm.searchedDevice && !_vm.status.error)?_c('div',[_c('v-flex',{staticClass:"text-center"},[(_vm.status.loading)?_c('v-chip',[_vm._v("LOADING...")]):_vm._e()],1),(!_vm.status.loading)?_c('v-simple-table',[[_c('thead',[_c('tr',[_c('th',[_vm._v("Terminal ID")]),_c('th',[_vm._v("Shop Code")]),_c('th',[_vm._v("Description")]),_c('th',[_vm._v("Name")]),_c('th',{staticClass:"text-center"},[_vm._v("History")]),(_vm.searchedDevice.name == null)?_c('th',{staticClass:"text-center"},[_vm._v("Link Device")]):(_vm.searchedDevice.name != null)?_c('th',{staticClass:"text-center"},[_vm._v("Unlink Device")]):_vm._e(),_c('th',{staticClass:"text-center"},[_vm._v("Rejected Events")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.searchedDevice.device.TerminalId))]),_c('td',[_vm._v(_vm._s(_vm.searchedDevice.device.ShopCode))]),_c('td',[_vm._v(_vm._s(_vm.searchedDevice.device.Description))]),_c('td',[_c('span',{staticClass:"pointer",on:{"click":function($event){return _vm.goToOrg(_vm.searchedDevice.id)}}},[_vm._v(_vm._s(_vm.searchedDevice.name))])]),_c('td',{staticClass:"text-center"},[(_vm.searchedDevice.name)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","color":"accent"},on:{"click":function($event){return _vm.fetchHistory(_vm.searchedDevice.device)}}},on),[_c('v-icon',[_vm._v("mdi-view-list")])],1)]}}],null,false,1626441594)},[_c('span',[_vm._v("Transaction History")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","color":"accent"},on:{"click":function($event){return _vm.fetchLinkedHistory(_vm.searchedDevice.name, _vm.searchedDevice.device)}}},on),[_c('v-icon',[_vm._v("link")])],1)]}}],null,false,737361734)},[_c('span',[_vm._v("Link History")])])],1),(_vm.searchedDevice.name == null)?_c('td',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary","dark":""}},on),[_c('v-icon',[_vm._v("link")])],1)]}}],null,false,970467111),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Link Device to Organisation")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.organisations,"placeholder":"Select Organisation","return-object":"","hide-details":""},model:{value:(_vm.selectedOrganisation),callback:function ($$v) {_vm.selectedOrganisation=$$v},expression:"selectedOrganisation"}}),_c('v-text-field',{attrs:{"placeholder":"Description","rules":[function (deviceDescription) { return !!deviceDescription || 'Description is required'; }]},model:{value:(_vm.deviceDescription),callback:function ($$v) {_vm.deviceDescription=$$v},expression:"deviceDescription"}})],1)],1)],1)],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"color":"accent darken-1","text":"","disabled":_vm.selectedOrganisation == null || _vm.deviceDescription == null},on:{"click":function($event){return _vm.linkThisDevice()}}},[_vm._v("Save")])],1)],1)],1)],1):(_vm.searchedDevice.name != null)?_c('td',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary","dark":""}},on),[_c('v-icon',[_vm._v("link_off")])],1)]}}],null,false,2535886263),model:{value:(_vm.unlinkDialog),callback:function ($$v) {_vm.unlinkDialog=$$v},expression:"unlinkDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Unlink Device")])]),_c('v-card-text',[_vm._v(" Are you sure you want to unlink this device ("+_vm._s(_vm.searchedDevice.device.TerminalId)+") from: "+_vm._s(_vm.searchedDevice.name)+" ")]),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.unlinkDialog = !_vm.unlinkDialog}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"color":"accent darken-1","text":""},on:{"click":function($event){return _vm.unlinkThisDevice(_vm.searchedDevice.device.TerminalId)}}},[_vm._v("Ok")])],1)],1)],1)],1):_vm._e(),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","color":"accent"},on:{"click":function($event){return _vm.fetchRejectedEvents(_vm.searchedDevice.device)}}},on),[_c('v-icon',[_vm._v("mdi-view-list")])],1)]}}],null,false,4187161353)},[_c('span',[_vm._v("Fetch Rejected Events")])])],1)])])]],2):_vm._e()],1):_vm._e(),(_vm.status.error)?_c('v-chip',{attrs:{"color":"red"}},[_vm._v("Could not find device")]):_vm._e()],1):_vm._e(),(_vm.showResults)?_c('router-view'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }