<template>
	<S2SForm title="Search Devices">
		<S2SCard wrap align-center fluid v-if="!showResults">
			<v-row>
				<v-col cols="12" md="6">
					<v-text-field
						v-model="deviceId"
						autofocus
						label="Terminal ID"
						placeholder="Search by Terminal ID"
						required
						clearable
						type="text"
						append-icon="search"
						@keypress.enter="search"
						@click:append="search"
						:loading="status.loading"
					></v-text-field>
				</v-col>
			</v-row>

			<div v-if="searchedDevice && !status.error">
				<v-flex class="text-center">
					<v-chip v-if="status.loading">LOADING...</v-chip>
				</v-flex>
				<v-simple-table v-if="!status.loading">
					<template>
						<thead>
							<tr>
								<th>Terminal ID</th>
								<th>Shop Code</th>
								<th>Description</th>
								<th>Name</th>
								<th class="text-center">History</th>
								<th class="text-center" v-if="searchedDevice.name == null">Link Device</th>
								<th class="text-center" v-else-if="searchedDevice.name != null">Unlink Device</th>
								<th class="text-center">Rejected Events</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{{ searchedDevice.device.TerminalId }}</td>
								<td>{{ searchedDevice.device.ShopCode }}</td>
								<td>{{ searchedDevice.device.Description }}</td>
								<td>
									<span @click="goToOrg(searchedDevice.id)" class="pointer">{{ searchedDevice.name }}</span>
								</td>
								<td class="text-center">
									<v-tooltip top v-if="searchedDevice.name">
										<template v-slot:activator="{ on }">
											<v-btn text v-on="on" color="accent" @click="fetchHistory(searchedDevice.device)">
												<v-icon>mdi-view-list</v-icon>
											</v-btn>
										</template>
										<span>Transaction History</span>
									</v-tooltip>
									<v-tooltip top>
										<template v-slot:activator="{ on }">
											<v-btn text v-on="on" color="accent" @click="fetchLinkedHistory(searchedDevice.name, searchedDevice.device)">
												<v-icon>link</v-icon>
											</v-btn>
										</template>
										<span>Link History</span>
									</v-tooltip>
								</td>
								<td class="text-center" v-if="searchedDevice.name == null">
									<v-dialog v-model="dialog" persistent max-width="600px">
										<template v-slot:activator="{ on }">
											<v-btn icon color="primary" dark v-on="on">
												<v-icon>link</v-icon>
											</v-btn>
										</template>
										<v-card>
											<v-card-title>
												<span class="headline">Link Device to Organisation</span>
											</v-card-title>
											<v-card-text>
												<v-container>
													<v-row>
														<v-col cols="12">
															<v-autocomplete
																:items="organisations"
																placeholder="Select Organisation"
																v-model="selectedOrganisation"
																return-object
																hide-details
															></v-autocomplete>
															<v-text-field
																placeholder="Description"
																:rules="[deviceDescription => !!deviceDescription || 'Description is required']"
																v-model="deviceDescription"
															></v-text-field>
														</v-col>
													</v-row>
												</v-container>
											</v-card-text>
											<v-card-actions>
												<div class="flex-grow-1"></div>
												<v-btn color="blue darken-1" text @click="dialog = !dialog">Close</v-btn>
												<v-btn
													color="accent darken-1"
													text
													@click="linkThisDevice()"
													:disabled="selectedOrganisation == null || deviceDescription == null"
													>Save</v-btn
												>
											</v-card-actions>
										</v-card>
									</v-dialog>
								</td>
								<td v-else-if="searchedDevice.name != null" class="text-center">
									<v-dialog v-model="unlinkDialog" persistent max-width="600px">
										<template v-slot:activator="{ on }">
											<v-btn icon color="primary" dark v-on="on">
												<v-icon>link_off</v-icon>
											</v-btn>
										</template>
										<v-card>
											<v-card-title>
												<span class="headline">Unlink Device</span>
											</v-card-title>
											<v-card-text>
												Are you sure you want to unlink this device ({{ searchedDevice.device.TerminalId }}) from:
												{{ searchedDevice.name }}
											</v-card-text>
											<v-card-actions>
												<div class="flex-grow-1"></div>
												<v-btn color="blue darken-1" text @click="unlinkDialog = !unlinkDialog">Close</v-btn>
												<v-btn color="accent darken-1" text @click="unlinkThisDevice(searchedDevice.device.TerminalId)">Ok</v-btn>
											</v-card-actions>
										</v-card>
									</v-dialog>
								</td>
								<td>
									<v-tooltip top>
										<template v-slot:activator="{ on }">
											<v-btn text v-on="on" color="accent" @click="fetchRejectedEvents(searchedDevice.device)">
												<v-icon>mdi-view-list</v-icon>
											</v-btn>
										</template>
										<span>Fetch Rejected Events</span>
									</v-tooltip>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</div>

			<v-chip v-if="status.error" color="red">Could not find device</v-chip>
		</S2SCard>
		<router-view v-if="showResults"></router-view>
	</S2SForm>
</template>

<script>
import Vue from "vue";
import { Utils } from "@/utils";
import { LookupsApi } from "@/api/lookups-api";

export default Vue.extend({
	name: "SearchDevice",

	computed: {
		searchedDevice: function() {
			return this.$store.state.devices.searchedDevice;
		},
		status: function() {
			return this.$store.state.devices.status;
		}
	},

	watch: {
		"$route.name": {
			handler: function() {
				this.route = this.$route.name;
				if (this.$route.name !== "searchDevices") {
					this.showResults = true;
				} else {
					this.showResults = false;
				}
			},
			immediate: true
		}
	},

	data: function() {
		return {
			route: this.$route.name,
			showResults: false,
			dialog: false,
			unlinkDialog: false,
			device: null,
			deviceId: null,
			organisations: [],
			selectedOrganisation: null,
			deviceDescription: null,
			lookupsApi: LookupsApi.fetchLookups
		};
	},

	created: function() {
		// clear searched device
		this.$store.commit("devices/clearSearchedDevice");
	},

	mounted: async function() {
		this.lookupsApi("organisations").then(res => {
			this.organisations = res.data.results.map(el => {
				return { text: el.name, value: el.code };
			});
		});
	},

	methods: {
		async search() {
			// clear selectedOrganisation & deviceDescription
			this.selectedOrganisation = null;
			this.deviceDescription = null;
			this.$store.dispatch("devices/fetchDeviceById", this.deviceId);
		},
		async linkThisDevice() {
			if (this.selectedOrganisation != null) {
				if (this.deviceDescription != null || this.deviceDescription != "") {
					this.dialog = false;

					// Link device
					await this.$store.dispatch("devices/linkDevice", {
						TerminalId: this.deviceId,
						ShopCode: this.selectedOrganisation.value,
						Description: this.deviceDescription
					});
					this.search();
				}
			}
		},
		async unlinkThisDevice(deviceId) {
			this.$store.dispatch("devices/unlinkDevice", deviceId);
			this.unlinkDialog = false;
			this.deviceId = null;
		},
		async fetchHistory(item) {
			let date = Date.now();
			let d = new Date(date),
				month = "" + (d.getMonth() + 1),
				day = "" + d.getDate(),
				year = d.getFullYear();

			if (month.length < 2) month = "0" + month;
			if (day.length < 2) day = "0" + day;

			let historyDate = [year, month, day].join("-");

			let params = { id: item.TerminalId, date: historyDate };

			let terminalId = await this.$store.dispatch("devices/fetchLinkedDeviceHistory", params);

			if (terminalId) {
				this.$router.push({ name: `device-transaction-history` });
			}
		},
		async fetchLinkedHistory(orgName, device) {
			let terminalId = await this.$store.dispatch("devices/fetchLinkedDeviceLinkHistory", device.TerminalId);

			if (terminalId) {
				this.$router.push({ name: `device-link-history`, params: { orgName: orgName, orgCode: device.ShopCode } });
			}
		},
		goToOrg(orgId) {
			this.$router.push({ name: "organisation-profile", params: { orgId: orgId } });
		},
		fetchRejectedEvents(device) {
			this.$router.push({ name: "rejected-events", params: { deviceId: device.TerminalId } });
		}
	}
});
</script>

<style scoped>
.pointer {
	cursor: pointer;
}
</style>
